import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { compare } from 'fast-json-patch';
import { useAuth } from 'react-oidc-context';
import { t } from 'i18next';

import { ApprovalDataGrid } from 'components/thestral';

import { CHARACTERS_URL, HTTP_METHOD } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { Character, Diff, User, Approvals } from 'types';

type Props = {
  char: Character;
  approvers: User[];
};

export function DiffView(props: Readonly<Props>) {
  const auth = useAuth();

  const { data: revisionData, loading: revisionLoading } = useFetch<Diff[]>(
    `${CHARACTERS_URL}/${props.char.id}/diff`
  );
  const {
    data: approvalsData,
    triggerRefetch: approvalsFetch,
    loading: approvalsLoading,
  } = useFetch<Approvals>(`${CHARACTERS_URL}/${props.char.id}/approval`);
  const fetchFromBackend = useFetchFromBackend();

  const [latest, setLatest] = useState<Diff>(undefined);
  const [before, setBefore] = useState<Diff>(undefined);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (!revisionLoading && (!latest || !before)) {
      setBefore(revisionData?.pop());
      setLatest(revisionData?.pop());
    }
  }, [revisionLoading]);

  const { oldContent, newContent } = useMemo(() => {
    const patch = compare(latest?.playerCharacter, before?.playerCharacter);
    return patch.reduce(
      (acc, diff) => {
        const path = diff.path.split('/')[1];

        if (path.includes('state')) return acc;
        if (path.includes('socialMediaAccounts')) return acc;
        if (path.includes('userId')) return acc;
        if (path.includes('profileId')) return acc;
        if (path.includes('profileVisibility')) return acc;
        if (path.includes('imagePath')) return acc;
        if (path.includes('id')) return acc;
        if (path.includes('playerName')) return acc;

        if (before?.revisionId != 0) {
          acc.oldContent.push(content([path, before?.playerCharacter[path]]));
        }
        acc.newContent.push(content([path, latest?.playerCharacter[path]]));

        return acc;
      },
      { oldContent: [] as Array<ReactNode>, newContent: [] as Array<ReactNode> }
    );
  }, [latest, before]);

  const onRowClick = (elem) => {
    if (auth.user?.profile.email !== elem.row.email) {
      return;
    }
    const state = approvalsData?.approvals[elem.row.id];
    approve(!state);
  };

  function approve(newState: boolean) {
    fetchFromBackend(
      `${CHARACTERS_URL}/${props.char.id}/approval?approval=${newState}`,
      { method: HTTP_METHOD.PATCH }
    )
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          approvalsFetch();
        }

        showSnackbar(
          `${t('Components.Characters.Approvals.TitleShort')} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error));
  }

  function content(elem): ReactNode {
    const i18nKey = elem[0].charAt(0).toUpperCase() + elem[0].slice(1);
    let i18n = t(`Components.PlayerCharacters.${i18nKey}`, {
      defaultValue: false,
    });
    if (!i18n) {
      i18n = t(`Generic.${i18nKey}`);
    }

    return (
      <Box sx={{ marginBottom: '20px' }}>
        <Typography
          sx={{
            '@media (max-width:600px)': {
              fontSize: '0.8rem',
              lineHeight: '2rem',
              wordBreak: 'break-all',
            },
          }}
          variant='body1'
          color='primary'
        >
          {i18n}
        </Typography>
        {typeof elem[1][0] === 'object' ? (
          elem[1].map((item) => (
            <Box
              key={item.name}
              sx={{ marginLeft: '10px', marginBottom: '10px' }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  marginTop: '25px',
                }}
                variant='caption'
              >
                {item.name}
              </Typography>
            </Box>
          ))
        ) : (
          <>
            {Array.isArray(elem[1]) ? (
              elem[1].map((item) => (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    marginTop: '25px',
                  }}
                  key={item.slice(5)}
                  variant='caption'
                >
                  {(item as string) + '\n'}
                </Typography>
              ))
            ) : (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  marginTop: '25px',
                  whiteSpace: 'pre-line'
                }}
                variant='caption'
              >
                {elem[1] as string}
              </Typography>
            )}
          </>
        )}
        <hr />
      </Box>
    );
  }

  return (
    <>
      {approvalsData && (
        <>
          <Grid item xs={12}>
            <Typography
              component='div'
              variant='body1'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '25px',
              }}
            >
              {t('Generic.Approvals')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ApprovalDataGrid
              approvers={props.approvers}
              approvalData={approvalsData}
              loading={approvalsLoading}
              onRowClick={onRowClick}
            />
          </Grid>
        </>
      )}
      {revisionLoading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component='div'
              variant='body1'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '25px',
              }}
            >
              {t('Generic.Details')}
            </Typography>
          </Grid>
          {before?.revisionId != 0 && (
            <Grid item xs={6}>
              <Card raised>
                <CardContent
                  sx={{
                    backgroundColor: '#2f2f2f',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant='h4'
                    component='div'
                    sx={{ marginBottom: '0px' }}
                  >
                    {t('Components.Characters.Approvals.OldContent')}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='caption'
                    component='div'
                    sx={{ marginBottom: '15px' }}
                  >
                    Version: {before?.revisionId}
                  </Typography>
                  <hr />
                  {oldContent}
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={before?.revisionId == 0 ? 12 : 6}>
            <Card raised>
              <CardContent
                sx={{
                  backgroundColor: '#2f2f2f',
                  whiteSpace: 'pre-line'
                }}
              >
                <Typography
                  gutterBottom
                  variant='h4'
                  component='div'
                  sx={{ marginBottom: '0px' }}
                >
                  {t('Components.Characters.Approvals.NewContent')}
                </Typography>
                <Typography
                  gutterBottom
                  variant='caption'
                  component='div'
                  sx={{ marginBottom: '15px' }}
                >
                  Version: {latest?.revisionId}
                </Typography>
                <hr />
                {newContent}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
